<!--
 * @Autor: chenqinglong
 * @Date: 2021-07-06 15:47:39
 * @LastEditors: chenqinglong
 * @LastEditTime: 2021-07-07 11:33:01
 * @Introduce: 操作日志
 * @FilePath: \intelligent-performance-vuee:\WWW\workbench-front\src\views\Setting\Operation.vue
-->
<template>
    <div class="setting">
        <div class="param-title">操作日志</div>
        <div class="content">

            <!-- 搜索 -->
            <div class="flex-end search-form display_flex_ac_jb">
                <div class="display_flex_ac_jc">
                    <div class="display_flex_ac">
                        <span class="title">操作类型：</span>
                        <el-select v-model="curd" placeholder="请选择" size="small" @change="getDataList()" style="width:90px;">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                        
                    <div class="display_flex_ac" style="margin: 0 16px;">
                        <span class="title">操作时间：</span>
                        <el-date-picker
                            size="small"
                            type="daterange"
                            v-model="timeZone"
                            range-separator="至"
                            style="width: 250px;"
                            @change="getDataList()"
                            end-placeholder="结束日期"
                            start-placeholder="开始日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                        >
                        </el-date-picker>
                    </div>

                    <el-button size="small" type="primary" @click="onReset">重置</el-button>
                </div>
                
                <div class="right">
                    <div class="display_flex_ac">
                        <el-select v-model="operation" size="small" style="width:100px;">
                            <el-option label="操作人员" value="1"></el-option>
                            <el-option label="操作账号" value="2"></el-option>
                        </el-select>
                        <el-input size="small" class="inputs" style="width:200px;" v-model="keyword" @input="clearInput" @keyup.enter.native="goSearch" placeholder="请输入任务名称">
                        </el-input>
                        <i @click="goSearch" class="el-icon-search search-btn"></i>
                    </div>
                </div>
            </div>

            <!-- 表内容 -->
            <el-table :data="tableData" v-loading="loading">
                <el-table-column prop="id" label="操作ID" width="80">
                    <template slot-scope="scope">
                        <div class="task-name">{{ scope.row.id }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="userid" label="操作人" width="120">
                    <template slot-scope="scope">
                        <wxComponent type="userName" :openid="scope.row.userid"/>
                    </template>
                </el-table-column>
                <el-table-column prop="userid" label="操作账号" width="150">
                    <template slot-scope="scope">
                        <div>{{ scope.row.userid }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="curd" label="操作类型" width="90" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.curd }}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="journal_controller" label="操作模块" width="100" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.journal_controller }}</div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="content" label="操作内容">
                    <template slot-scope="scope">
                        <div>{{ scope.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="操作时间" width="160">
                    <template slot-scope="scope">
                        <div>{{ scope.row.create_time }}</div>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="flex-end" v-show="tableData.length">
                <el-pagination
                    background
                    :current-page="page"
                    @current-change="turnPage"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getlstPage } from '@/api/workbench'
import wxComponent from "@/components/wx";
export default {
    components: {
        wxComponent
    },
    data() {
        return {
            loading: false, // 加载状态
            
            // 搜索
            options: [
                { value: '', label: '全部' },
                { value: '1', label: '新增' },
                { value: '3', label: '修改' },
                { value: '2', label: '删除' },
                { value: '4', label: '其他' },
            ],
            curd: '',
            keyword: '', // 关键词
            timeZone: [], // 时间区域
            operation: '1', // 1 操作人员 2 操作账号

            tableData: [], // 表格数据 

            // 分页
            page: 1, //默认一页
            limit: 10, //默认每页十条
            total: 0, // 总条数
        }
    },
    created(){
        this.getDataList() // 获取数据
    },
    methods: {
        //清空搜索返回全部
        clearInput() {
            if (!this.keyword) {
                this.getDataList()
            }
        },

        //搜索关键字
        goSearch() {
            if (this.keyword) {
                if (this.keyword.length > 20 || this.keyword.length < 2) {
                    this.$message({message: '关键字仅限2到20个字以内',type: 'warning'})
                } else {
                    this.getDataList()
                }
            } else {
                this.$message({message: '请输入搜索关键字',type: 'warning'})
            }
        },

        // 获取日志列表
        getDataList(){
            this.loading=true;
            getlstPage({
                page: this.page, // 分页数
                limit: this.limit, // 每页条数
                btime: this.timeZone[0] || '', // 开始时间
                etime: this.timeZone[1] || '', // 结束时间
                curd: this.curd, // '' 全部 1新增 2删除 3修改 4其他
                operation_type: this.operation, // 1操作人员 2操作账号
                keyword: this.keyword // 操作人关键字
            }).then(res => {
                this.tableData = res.data.list
                this.total = res.data.count
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },

        // 翻页
        turnPage(page) {
            this.page = page
            this.getDataList()
        },

        // 重置
        onReset(){
            this.page = 1 // 初始
            this.curd = '' // 初始
            this.keyword = '' // 初始
            this.timeZone = [] // 初始
            this.operation = '1' // 初始
            this.getDataList()
        }
    },
};
</script>

<style lang="less" scoped>
    .setting {
        .param-title {height: 48px;line-height: 48px;border-bottom: 1px solid #DBDBDB;padding-left: 24px;}
        .content {padding: 24px;

            // 搜索
            .search-form {margin: 0 0 24px;
                .title{font-size: 14px;font-weight: 400;color: #303133;}
                .right{position: relative;
                    /deep/.el-input--small .el-input__inner{border-radius: 0 4px 4px 0;}
                    /deep/.inputs  .el-input__inner{padding: 0 35px 0 15px;}
                    /deep/.el-input--suffix .el-input__inner{border-radius: 4px 0 0 4px;}
                    /deep/.search-btn {position: absolute;right: 10px;top: 8px;cursor: pointer;}
                }
                /deep/.el-date-editor .el-range-separator{width: auto;}
            }

            // table表
            /deep/ .el-table { margin:0 auto 24px;
                .task-name { margin-bottom: 5px; }
                /deep/ thead {
                    /deep/ th {padding: 8px 0;font-weight: 400;color: #606266;}
                    /deep/ tr, th, td { background: #DFE9F5 !important; }
                }
            }
        }
    }
    
    .display_flex_ac{display: flex;align-items: center;}
    .display_flex_ac_jc{display: flex;align-items: center;justify-content: center;}
    .display_flex_ac_jb{display: flex;align-items: center;justify-content: space-between;}
</style>
